<!--상품 리스트 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="flex">
				<div class="w-1/7 mx-4">
					<h6>카테고리</h6>
					<vSelect v-model="opt.category" :clearable="false" :options="categoryOpts" />
				</div>
				<div class="w-1/8 ml-8">
					<h6>키워드</h6>
					<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-1/3 mx-2">
					<h6>&nbsp;</h6>
					<div>
						<b-form-input
							class=""
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
					</div>
				</div>
				<div>
					<h6 class="block">&nbsp;</h6>
					<b-button class="px-10 top-0.5" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<b-button class="mb-4 float-right z-10" @click="excelExport">엑셀 다운로드 요청</b-button>
			<tb :inf="inf" :res="res" limit="15" />
		</b-card>
	</div>
</template>

<script>
const searchKeyOpts = [
		{ label: '브랜드', value: 'brandName' },
		{ label: '상품그룹명', value: 'productGroupName' },
		{ label: '상품 idx', value: 'productGroupIdx' },
		{ label: '파트너사', value: 'companyName' },
	],
	defOpt = {
		category: { label: '전체', value: '' },
		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	name: 'keep',
	data() {
		return {
			categoryOpts: [],
			searchKeyOpts,
			defOpt,

			opt: { ...defOpt },
			lastOpt: {},
			res: {},

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },

				{ title: '내돈내산', key: 'isSelfDeal', size: 2 },
				{ title: '상품 idx', key: 'productGroupIdx', size: 2 },
				{ title: '파트너사', key: 'companyName', size: 3 },
				{ title: '브랜드', key: 'brandName', size: 3 },
				{ title: '상품그룹명', key: 'productGroupName', size: 6 },

				{ title: '상품타입', key: 'productType', size: 3 },
				{ title: '소비자가', key: 'customerPrice', size: 3 },

				{ title: '핫딜가', key: 'salePrice', size: 3 },
				{ title: '렌탈개월수', key: 'rentalMonth', size: 3 },
				{ title: '렌탈총가격', key: 'rentalAllPrice', size: 3 },
				{ title: '렌탈월비용', key: 'rentalPrice', size: 3 },
				{ title: '수수료', key: 'fee', size: 2 },
				{ title: '수수료<br>(원)', key: 'hottistSalesFee', size: 2 },
				{ title: '카테고리', key: 'category', size: 3 },
				{ title: '노출상태<br>(변동등급)', key: 'productGroupClass', size: 3 },
				{ title: '등록일', key: 'enrollDate', size: 3 },
				{ title: '수정', model: 'button', icon: 'pencil-square', size: 2, func: this.goEdit },
			],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				category = getDropdownValue(opt.category),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = {
					paging: getPaging(0, 15),
				},
				//선택 조건에 없는 필수값들 입력
				error = []

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (category) data.category = category

			if (searchVal) data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
			if (error.length)
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(error)
			else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				return this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('productSelect/productList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.isSelfDeal = v.isSelfDeal ? '가능' : '불가능'

						v.rentalAllPrice = v.rentalPrice * v.rentalMonth
						;['customerPrice', 'salePrice', 'rentalAllPrice', 'rentalPrice', 'hottistSalesFee'].map(k => {
							v[k] = comma(v[k])
						})

						v.enrollDate = v.enrollDate.replace('T', ' ')
						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()
			ex.dropdown(opt.category, this.categoryOpts, '카테고리', '전체')
			ex.search(opt.searchKey, searchKeyOpts)
			ex.go(this)
		},
		goEdit(v) {
			page('productAdd', { productGroupIdx: v.productGroupIdx })
		},
	},
	created() {
		getConfig('menu_tab_type').then(res => {
			//this.categoryOpts = res.content_category
			this.categoryOpts = res.menu_tab_type.filter(v => v.value === '' || (v.value > 0 && v.value < 6))
			this.search()
		})
	},
}
</script>
